
import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../reducers/userReducer'
import deskSlice from '../reducers/deskReducer'
import sessionSlice from '../reducers/sessionReducer'
import favSlice from '../reducers/favReducer'

export default configureStore({
  reducer: {
    user: userSlice,
    desk: deskSlice,
    session: sessionSlice,
    fav: favSlice
  }
})
