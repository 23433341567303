import { Breadcrumbs, 
   Stack, 
   Link, 
   LinearProgress, 
   Chip,
   Box,
   TextField,
   Paper, 
   Button,
   Typography, 
   Tooltip,
   Alert,
   IconButton,
   Divider,
   } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useMarkTodoDone, useProjects, useTodo, useTodos, useUpdateEstimation } from "../services/creativedeskApi"
import { useDispatch, useSelector } from "react-redux";
import { setProject, setScreen, setTodo } from "../reducers/deskReducer";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { TABS } from "../services/constants";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {ago, calendar, toHoursAndMinutes} from '../services/momentHelper'

import StartIcon from '@mui/icons-material/PlayArrow';
import { OpenInBrowser } from "@mui/icons-material";
import Todos from "./Todos";
import PipeToChip from "../components/PipeToChip";
import Loader from "../components/Loader";
import { LoadingButton } from "@mui/lab";
import linkOpener from "../services/linkOpener";
import FavButton from "../components/FavButton";
import SaveIcon from '@mui/icons-material/Save';
import { useState } from "react";
import AdsClickIcon from '@mui/icons-material/AdsClick';

export default function Todo({projectId, todoId, isStarting, startTodo}) {    
    if(!todoId) throw new Error("todoId is required");
    const todo = useTodo(projectId, todoId);
    const todos = useTodos(projectId);
    const projects = useProjects();    
    const estimationMutation = useUpdateEstimation(todoId);
    const project = projects.data?.find(project => project.id == projectId);
    const dispatch = useDispatch();
    const [ estimation, setEstimation ] = useState();
    const markTodoDoneMutation = useMarkTodoDone(todoId);

    const session = useSelector(state => state.session);
    
    const selectProject = project => () => {
      dispatch(setProject(project));
      dispatch(setScreen(TABS.todos));
    }
    
    const selectTodo = (todo) => () => {
      dispatch(setTodo(todo));
      dispatch(setScreen(TABS.todo));
    }
    
    const todoData = todo.data;

    const isParentTask = todoData?.subtasksCount > 0;
    const parentTodo = todos.data?.find(todo => todo.id == todoData?.parentId);

    const markupMap = {
      h: ({node, ...props}) => <Typography variant="h3" sx={{marginBottom: 0.5}} {...props} color="text.primary" />,
      p: ({node, ...props}) => <Typography variant="body2" sx={{marginBottom: 0.5, overflowX: 'auto'}} {...props} color="text.secondary"/>,
      a: ({node, ...props}) => <Link sx={{marginBottom: 0.5}} target="_blank" onClick={linkOpener} {...props} />
    }    

    if(todo.isSuccess) return (
      <Stack>
        <Breadcrumbs separator={<KeyboardArrowRight/>} sx={{ px: 2, mt: 2}}>      
          <Link underline="hover" onClick={()=>dispatch(setScreen(TABS.projects))}>Projects</Link>      
          <Link underline="hover" onClick={selectProject(project)}><PipeToChip text={project?.name}/></Link>
          {parentTodo && <Link underline="hover" onClick={selectTodo(parentTodo)}>{parentTodo.name}</Link>}
          {/* <Typography color="text.primary">{todoData?.name.length > 20 ? todoData?.name.substring(0,20) + '…' : todoData?.name }</Typography> */}
        </Breadcrumbs>
        <Paper sx={{          
          mx: 2,
          mt: 2,
        }} elevation={1}>
          {todo.isSuccess && <Box >
            <Typography variant="h2" sx={{p:2, mb:2}}>{todoData?.name}</Typography>   
            { isParentTask && <Chip label="Parent task" color="warning" variant="outlined" sx={{mx:2, mb: 2}}/> }
            { !project && <Alert severity="error" sx={{m: 2}}>You are not assigned to this project</Alert> }
            <Grid container spacing={2} sx={{mx: 1}}>
              
              <Grid xs={6} sm={3}>
                { todoData.estimation > 0 ?
                <LoadingButton variant="contained" color="success" fullWidth startIcon={<StartIcon />}
                  onClick={()=>startTodo(todoData.id, todoData.name, todoData.todoType, project.id, project.name)}
                  loading={isStarting}
                  disabled={isParentTask || session.todoId==todoData.id || !project}>Start</LoadingButton>
                :              
                  <TextField label="Estimate" color="primary" variant="outlined"
                    size="medium"
                    onChange={e=>setEstimation(e.target.value)}
                    error={estimationMutation.isError}
                    fullWidth  
                    InputProps={{
                      min: 0.25, step: 0.25, required: true,
                      type:  'number',
                      sx: {pr: 1},
                      endAdornment: <IconButton 
                        onClick={()=>estimationMutation.mutate(estimation)}
                        disabled={!estimation || estimationMutation.isPending || estimationMutation.isSuccess}
                      ><SaveIcon /></IconButton>
                    }}                />                  
                }
              </Grid>
              
              <Grid xs={6} sm={3}>
                <FavButton project={project} todo={todoData}/>                
              </Grid>
              <Grid xs={6} sm={3}>
                <Button variant="outlined" fullWidth color="secondary" startIcon={<OpenInBrowser />}
                  href={`https://www.creativedesk.de/projects/${todoData.projectId}/todos/${todoData.id}`}
                  onClick={linkOpener}
                  target="_blank">Desk</Button>
              </Grid>
              <Grid xs={6} sm={3}>
                <Button variant="outlined" fullWidth color="secondary" startIcon={<OpenInBrowser />} 
                  href={todoData.jiraLink} disabled={!todoData.jiraLink} onClick={linkOpener}
                  target="_blank">Jira</Button>
              </Grid>              
            </Grid>            

            <Grid container spacing={2} sx={{mx: 1, mt: 3, mb: 2}}>
              <Grid xs={6} md={3}>
                  <Tooltip title={calendar(todoData?.createdAt)}>
                  <TextField label="Created" 
                    value={ago(todoData?.createdAt)}                     
                    fullWidth InputProps={{
                    readOnly: true,
                  }} /></Tooltip>
              </Grid>
              <Grid xs={6} md={3}>
                  <Tooltip title={calendar(todoData?.updatedAt)}>
                  <TextField label="Last activity" 
                    value={ago(todoData?.updatedAt)}                     
                    fullWidth InputProps={{
                    readOnly: true,
                  }} /></Tooltip>
              </Grid>
              <Grid xs={6} md={3}>
                  <TextField label="Estimated" value={todoData?.estimation ? toHoursAndMinutes(todoData?.estimation) : "none"} fullWidth/>
              </Grid>
              <Grid xs={6} md={3}>
                  <TextField label="Worked" value={todoData?.duration ? toHoursAndMinutes(todoData?.duration) : "none"} fullWidth/>
              </Grid>
              <Grid xs={12} sx={{py:3}}>
                <LinearProgress {...todoData.progress}/>                          
              </Grid>
              <Grid xs={4} md={4}>
                <TextField label="Kind" value={todoData?.todoType} fullWidth/>
              </Grid>
              <Grid xs={4} md={4}>
                <TextField label="Priority" value={todoData?.priority} fullWidth/>
              </Grid>
              <Grid xs={4} md={4}>
                {/* <TextField label="Status" value={todoData?.status} fullWidth/> */}
                <TextField label="URL" value={`desk://${todoData?.id}-${todoData?.projectId}`} fullWidth onFocus={e=>e.target.select()}/>
              </Grid>              
            </Grid>
            
          </Box> }
        </Paper>
        { todoData?.description && <Paper sx={{          
          mx: 2,
          mt: 2,
          p: 2
        }}>
          <Typography variant="h3" sx={{mb:2}}>Description</Typography>
          <Typography variant="body2" component="div" color="text.secondary">
            <Markdown remarkPlugins={remarkGfm} components={{
              p: markupMap.p,                
              h1: markupMap.h,
              h2: markupMap.h,
              h3: markupMap.h,
              h4: markupMap.h,
              h5: markupMap.h,
              a: markupMap.a                
            }}>{todoData?.description}</Markdown>
          </Typography>
        </Paper>}
        { !todoData.done && 
        <Paper  sx={{m:2, p:2, mt: 2}}>
        <Stack direction="row" gap={2}>
          
            <LoadingButton color="secondary" 
              onDoubleClick={()=>markTodoDoneMutation.mutate()} 
              title="Doubleclick" 
              endIcon={<AdsClickIcon fontSize="medium"/>}
              variant="outlined"
              loading={markTodoDoneMutation.isPending}>Mark as done</LoadingButton>
        </Stack>
        </Paper> 
        }
        
        {isParentTask && <Box>
          <Typography variant="h2" sx={{px:2, mt: 2}}>Subtasks</Typography>
          <Todos parentId={todoId} projectId={todoData.projectId} withBreadcrumbs={false}/>         
        </Box>}       
      </Stack>
    )
    if(todo.isError) return (
      <Alert severity="error" sx={{m: 2}}>TODO not found, or you have no access</Alert> 
    )

    return <Loader details={true}/>
}