import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { login as loginApi } from '../services/creativedeskApi'
import messageBus from '../services/messageBus';

export const login = createAsyncThunk('user/login', async ({username, password, remember}, thunkAPI) => {    
    const response = await loginApi(username, password);    
    messageBus.send('desk-storePassword', {username, password: remember ? password : null});
    return response;
})

const storeUser = (username, token) => {
  window.localStorage.setItem('username', username);
  window.localStorage.setItem('token', token);
};

const getUser = () => {
  return {
      username:  window.localStorage.getItem('username'),
      token: window.localStorage.getItem('token')
  }
};

const forgetUser = (andLogin=false) => {
  window.localStorage.removeItem('username');
  window.localStorage.removeItem('token');
  if(andLogin) {
    messageBus.send('desk-storePassword', {username: window.localStorage.getItem('userlogin'), password: null});
    window.localStorage.removeItem('userlogin')
  }  
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...getUser(),
    error: ''
  },
  reducers: {
    logout: (state, action) => {
      state.token = ''
      forgetUser(action.payload?.forgetUsername);
      window.history.pushState({}, '', '/');   
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.username = action.payload.user_name;
        state.token = action.payload.session;
        state.error = '';
        storeUser(action.payload.user_name, action.payload.session);
        window.history.pushState({}, '', '/'+action.payload.user_name+'/');  
      })
      .addCase(login.rejected, (state, action) => {        
        state.error = action.error.message;
      })
  }
})

export const { logout } = userSlice.actions

export default userSlice.reducer
