import { Breadcrumbs, 
  Stack, 
  Link,   
  Typography,
  Alert,
  Button,
  Box,
  Drawer
 } from "@mui/material";
import { useProjects, useTodos } from "../services/creativedeskApi"
import { changeSettings, setScreen, setTodo } from "../reducers/deskReducer";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { TABS } from "../services/constants";
import { useState } from "react";
import { sortAZ } from "./Projects";
import SearchBar from "../components/SearchBar";
import TodosList from "../components/TodosList";
import PipeToChip from "../components/PipeToChip";
import Loader from "../components/Loader";
import { Add, OpenInBrowser } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import linkOpener from "../services/linkOpener";
import AddTodo from "./AddTodo";

export default function Todos({projectId, parentId, startTodo = null, withBreadcrumbs = true}) {
  
  const todos = useTodos(projectId);
  const projects = useProjects();  
  const dispatch = useDispatch();
  const settings = useSelector(state => state.desk.settings);
  const [showAdd, setShowAdd] = useState(false);
  
  // Which project is currently loaded
  const project = projects.data?.find(project => project.id == projectId);

  // Which todo is currently loaded
  const currentTodo = useSelector(state => state.desk.todo);

  // Handle the selection of a todo
  const selectTodo = (todo) => () => {
    dispatch(setTodo(todo));
    dispatch(setScreen(TABS.todo));
  }

  const [search, setSearch] = useState('');

  const [sortBy, setSortBy] = useState(settings?.sortTodos);

  const setSortByAndStore = (value) => {
    setSortBy(value);
    dispatch(changeSettings({sortTodos: value}));
  }

  const afterAdd = (todo) => {
    dispatch(setTodo(todo));
    setShowAdd(false);    
    startTodo && startTodo(todo.id, todo.name, todo.todoType, project.id, project.name);
  }

  // This will be displayed. Filter by search
  let todosList = todos.data?.filter(todo => !todo.done).filter(todo => todo.name.toLowerCase().includes(search));
  // Only parents
  if(parentId) todosList = todosList?.filter(todo => todo.parentId == parentId);

  switch(sortBy) {
    case 'az':
      todosList?.sort(sortAZ);
      break;
    case 'date':
      todosList?.sort(sortByDate);
      break;
    case 'prio':
      todosList?.sort((a, b) => a.position > b.position ? 1 : -1);
      break;
  }
  if(todos.isSuccess && !project) return (
    <Alert severity="error" sx={{m: 2}}>You have no access to this project</Alert>
  )

  if(todos.isSuccess) return (
      <Box>
        <Drawer anchor="bottom" open={showAdd} elevation={3} 
          onClose={()=>setShowAdd(false)}
          PaperProps={{sx: {borderRadius: 2, m: 2, p: 2, pb: 4}}}
          >
          <AddTodo projectId={projectId} onSuccess={afterAdd}/>
        </Drawer>
        {withBreadcrumbs &&
          <Breadcrumbs separator={<KeyboardArrowRight />} sx={{ px: 2, mt: 2 }}>
              <Link underline="hover" onClick={()=>dispatch(setScreen(TABS.projects))} variant="plain">Projects</Link>
              <Typography color="text.primary"><PipeToChip text={project?.name}></PipeToChip></Typography>
          </Breadcrumbs>
        }
        <Grid container sx={{mb: 2}}>
          <Grid xs={12} md={7} sx={{ pt: 2, px: 2 }}>
            <SearchBar setSearch={setSearch} setSortBy={setSortByAndStore} sortBy={sortBy} withPriority/>
          </Grid>
          <Grid xs={6} md={3} sx={{alignSelf: 'center', pt: 2, px: 2}}>
            <Button variant="outlined" fullWidth color="secondary" 
                disabled={project.approveEstimations || project.todoNeedsParent || parentId}
                startIcon={<Add />}
                onClick={()=>setShowAdd(true)}>Add TODO</Button>                               
          </Grid>
          <Grid xs={6} md={2} sx={{alignSelf: 'center', pt: 2, px: 2}}>
            <Button variant="outlined" fullWidth color="secondary" startIcon={<OpenInBrowser />}
                href={`https://www.creativedesk.de/projects/${projectId}}`} onClick={linkOpener}               
                target="_blank">Desk</Button>        
          </Grid>
        </Grid>
        { todosList?.length > 0 ? 
          <TodosList todosList={todosList} currentTodo={currentTodo} selectTodo={selectTodo}/>
          : 
         <Alert severity="info" sx={{m: 2}}>No todos found</Alert> 
        }        
      </Box>
  )
  return <Loader/>
}

const sortByDate = (a, b) => {
  if (a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
}