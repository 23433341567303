import { Box } from "@mui/material";
import { useGiphy } from "../services/giphy";

export default function Giphy({keyword}) {
  const giphy = useGiphy(keyword);

  const url = giphy.data?.data?.images?.downsized?.url;
  return giphy.isSuccess && url && (
    <Box sx={{textAlign: 'center', fontSize: 0}}>
      <img src={url} style={{width: '100%', maxWidth: 500}}/>
    </Box>
  );
}