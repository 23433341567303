import { createSlice } from '@reduxjs/toolkit'
import { TABS } from '../services/constants';

const save = (value) => {
  window.localStorage.setItem('desk-settings', JSON.stringify(value));
}

const load = () => {
  return JSON.parse(window.localStorage.getItem('desk-settings', '{}'));
}

export const deskSlice = createSlice({
  name: 'desk',
  initialState: {
    screen: TABS.session,
    project: null,
    todo: null,
    initialIdle: false,
    startedOn: (new Date).getDate(),
    settings: {
      theme: 'dark',
      startWithIdle: false,
      startIdleAfterTodo: true,
      onlyIcons: false,
      forgetUsername: false,
      sortProjects: 'date',
      sortTodos: 'date',
      showHistoryOnSession: true,
      muteCreativedeskNotifications: false,
      noGifs: false,
      ...load()
    }
  },
  reducers: {
    setScreen: (state, action) => {
      state.screen = action.payload
    },
    setProject: (state, action) => {
      state.project = action.payload
    },
    setTodo: (state, action) => {
      state.todo = action.payload
    },
    initialIdleStarted: (state, action) => {
      state.initialIdle = action.payload;
    },
    changeSettings: (state, action) => {
      state.settings = {...state.settings, ...action.payload};
      save(state.settings)
    }
  }
})

export const { setScreen, setProject, setTodo, changeSettings, initialIdleStarted } = deskSlice.actions

export default deskSlice.reducer
