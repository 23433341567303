import { SPARE_TIME_PROJECT_ID } from "./constants";
import { todayDateYYYYMMDD } from "./momentHelper";

export function calculateTimes(data, addProjectInfo, currentDate = todayDateYYYYMMDD()) {
  const thisWeekStart = new Date();
  currentDate = currentDate.replaceAll('-', '/');
  thisWeekStart.setDate(thisWeekStart.getDate() - ((thisWeekStart.getDay() + 6) % 7)); // Start of the week (Monday)
  thisWeekStart.setHours(0, 0, 0, 0);

  const thisMonthStart = new Date();
  thisMonthStart.setDate(1); // Start of the month

  const todayData = data.filter(entry => entry.local_started_at.includes(currentDate));
  const thisWeekData = data.filter(entry => new Date(entry.local_started_at) >= thisWeekStart);
  const thisMonthData = data.filter(entry => new Date(entry.local_started_at) >= thisMonthStart);

  const todayStatistics = calculateProjectAndIdleTime(todayData, addProjectInfo);
  const thisWeekStatistics = calculateProjectAndIdleTime(thisWeekData, addProjectInfo);
  const thisMonthStatistics = calculateProjectAndIdleTime(thisMonthData, addProjectInfo);

  return {
    today: todayStatistics,
    week: thisWeekStatistics,
    month: thisMonthStatistics,
  };
}

// Function to calculate project and idle time
function calculateProjectAndIdleTime(data, addProjectInfo) {
  const statistics = {
    projects: [],
    idleTime: 0,
  };

  data.forEach(entry => {
    if (entry.project_id) {
      const projectId = entry.project_id;
      const projectInfo = {
        name: '',
        color: null,
        ...addProjectInfo(projectId)
      };


      const existingProject = statistics.projects.find(project => project.id === projectId);

      if (existingProject) {
        existingProject.duration += entry.worker_duration;
      } else {
        statistics.projects.push({ id: projectId, name: projectInfo.name, duration: entry.worker_duration, color: projectInfo.color, production: projectInfo.production });
      }
    } else {
      statistics.idleTime += entry.worker_duration;
    }
  });

  return statistics;
}

export function countSessionsWithoutNote(sessions) {
  const copy = sessions.slice(0,-1);
  return copy.filter(session => !session.notes_team_lang).length;
}

export function calcTotalTodoTime(times) {
  return times.projects.reduce((acc, cur) => acc + cur.duration, 0);
}

export function calcTotalSpareTime(times) {
  return times.projects.reduce((acc, cur) => acc + (cur.id == SPARE_TIME_PROJECT_ID ? cur.duration : 0), 0);
}
