import { 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  Stack, 
  ListItemText, 
  Tooltip,
  Box} from "@mui/material";
import { useProjects } from "../services/creativedeskApi";
import { changeSettings, setProject, setScreen, setTodo } from '../reducers/deskReducer';
import { useDispatch, useSelector } from "react-redux";
import { TABS } from "../services/constants";
import SearchBar from "../components/SearchBar";
import React, { useState } from "react";
import SavingsIcon from '@mui/icons-material/Savings';
import EuroIcon from '@mui/icons-material/Euro';
import PipeToChip from "../components/PipeToChip";
import Loader from "../components/Loader";

export const sortByDate = (a, b) => {
  if (a.lastActivityAt < b.lastActivityAt) {
    return 1;
  }
  if (a.lastActivityAt > b.lastActivityAt) {
    return -1;
  }
  return 0;
}

export const sortAZ = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

export default function Projects() {

    const projects = useProjects();
    const dispatch = useDispatch();
    const currentProject = useSelector(state => state.desk.project);
    const settings = useSelector(state => state.desk.settings);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState(settings?.sortProjects);

    const setSortByAndStore = (value) => {
      setSortBy(value);
      dispatch(changeSettings({sortProjects: value}));
    }

    const selectProject = (project) => () => {
      dispatch(setProject(project));
      dispatch(setTodo(null));
      dispatch(setScreen(TABS.todos));
    }

    const projectsList = projects.data?.filter(project => project.name.toLowerCase().includes(search));
    switch(sortBy) {
      case 'az':
        projectsList?.sort(sortAZ);
        break;
      case 'date':
        projectsList?.sort(sortByDate);
        break;
    }
    if(projects.isSuccess) return (
      <Stack>
        <Box sx={{p:2}}>
        <SearchBar setSearch={setSearch} setSortBy={setSortByAndStore} sortBy={sortBy}/>
        </Box>
        <List size="md" >
          {projectsList?.map(project => (
            <ListItem key={project.id} disablePadding>
              <ListItemButton onClick={selectProject(project)} selected={project.id == currentProject?.id}>
                <ListItemIcon>{project.clientId ? <EuroIcon color="primary" fontSize="large"/> : <SavingsIcon fontSize="large"/> }</ListItemIcon>
                <Tooltip title={project.description}>                  
                  <ListItemText secondary={project.description.length > 50 ? project.description.slice(0,50) + '…' : project.description}>
                    <PipeToChip text={project.name} />                    
                  </ListItemText>                
                </Tooltip>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    )

    return <Loader />
}