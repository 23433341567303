import { 
  Stack,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Switch,
  FormGroup,
  Box
 } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { changeSettings } from "../reducers/deskReducer";
import messageBus, {isWeb, askPermission} from "../services/messageBus";

const DARK_THEME_ID="dark";

export default function Settings({logoutCallback}) {
  
  const dispatch = useDispatch();
  const settings = useSelector(state => state.desk?.settings);

  const testNotification = () => {
    setTimeout(() => {
      messageBus.send('desk-message', {
        title: `Hello World from creativedesk!`,
        body: `You shall see this message for 5 seconds.`,  
        timeoutType: 'default',
      });
    }, 3000);
    
  }
  
  return (    
    <Stack>
      <Typography variant="h1" sx={{p:2}}>
        Settings
      </Typography>
      <Button variant="contained" color="error" onClick={() => logoutCallback(settings.forgetUsername)} sx={{m:2}}>Logout</Button>
      <Paper elevation={1} sx={{p:2, m:2}}>
        <FormGroup>
          <Typography variant="h2" sx={{mb:2}}>Look and feel</Typography>
          <FormControlLabel control={<Switch checked={settings.theme == DARK_THEME_ID} onChange={()=>dispatch(changeSettings({theme: settings.theme != DARK_THEME_ID ? DARK_THEME_ID : 'light'}))}/>} label="Dark mode" />
          <FormControlLabel control={<Switch checked={settings.onlyIcons} onChange={()=>dispatch(changeSettings({onlyIcons: !settings.onlyIcons}))}/>} label="Use smaller tabs with only icons"/>
          <FormControlLabel control={<Switch checked={settings.noGifs} onChange={()=>dispatch(changeSettings({noGifs: !settings.noGifs}))}/>} label="No animated gifs"/>
          <Typography variant="h2" sx={{my:2}}>Functional</Typography>
          <FormControlLabel control={<Switch checked={settings.startWithIdle} onChange={()=>dispatch(changeSettings({startWithIdle: !settings.startWithIdle}))}/>} label="Start Idle on startup"/>
          <FormControlLabel control={<Switch checked={settings.startIdleAfterTodo} onChange={()=>dispatch(changeSettings({startIdleAfterTodo: !settings.startIdleAfterTodo}))}/>} label="Start Idle after TODO"/>
          <FormControlLabel control={<Switch checked={settings.forgetUsername} onChange={()=>dispatch(changeSettings({forgetUsername: !settings.forgetUsername}))}/>} label="Forget username on logout"/>
          <FormControlLabel control={<Switch checked={settings.showHistoryOnSession} onChange={()=>dispatch(changeSettings({showHistoryOnSession: !settings.showHistoryOnSession}))}/>} label="Show history on Idle & Stop screens"/>
          <FormControlLabel control={<Switch checked={settings.muteCreativedeskNotifications} onChange={()=>dispatch(changeSettings({muteCreativedeskNotifications: !settings.muteCreativedeskNotifications}))}/>} label="Mute notifications from creativedesk (new Todos etc.)"/>
        </FormGroup>
      </Paper>
      <Box>
        <Button variant="outlined" color="secondary" onClick={testNotification} sx={{m:2}}>Test notification</Button>
        { isWeb && <Button variant="outlined" color="secondary" onClick={()=>askPermission()} sx={{m:2}}>Ask notification perms</Button> }
      </Box>
      
    </Stack>
  )

}