import { useQuery } from "@tanstack/react-query"
import { GIPHY_API_KEY } from "./constants";

export const useGiphy = (keyword) => {
  return useQuery({
    queryKey: ['giphy', keyword],
    queryFn: () => fetch(`https://api.giphy.com/v1/gifs/random?api_key=${GIPHY_API_KEY}&tag=${keyword}`)
      .then(res => {
        if(res.status === 429) {
          throw new Error('Too many requests');
        }
        return res.json()
      }),
    staleTime: 360000
  });
}