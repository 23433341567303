import { Skeleton, Stack } from "@mui/material";

export default function Loader({details=false}) {
  if(!details) return (
    <Stack p={2} gap={2}>
      <Skeleton variant="rectangular" height={40}/>
      <Skeleton variant="rectangular" height={40}/>
      <Skeleton variant="rectangular" height={40}/>      
    </Stack>
  );
  return (
    <Stack p={2} gap={2}>
      <Skeleton variant="rounded" height={30}/>
      <Skeleton variant="rounded" height={240}/>      
    </Stack>
  );
}
