import React from 'react';
import '@fontsource/roboto';
import './styles/App.css';

import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'

import Desk from './screens/Desk';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from './styles/theme';

const queryClient = new QueryClient();

window.addEventListener('focus', () => {
    queryClient.refetchQueries()
})

const App = () => {
    const theme = useSelector(state => state.desk.settings.theme);
    
    return (
        <ThemeProvider theme={theme == 'dark' ? darkTheme : lightTheme}>
            <QueryClientProvider client={queryClient}>            
                <CssBaseline />
                <Desk />            
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App;
