import { DOWNLOAD_URL, REQUIRED_DESK_VERSION } from "./constants";
import linkOpener from "./linkOpener";
import messageBus from "./messageBus";
import { compareVersions } from 'compare-versions';

export const versionHandler = (e, version) => {            
  if(compareVersions(version, REQUIRED_DESK_VERSION) < 0 ) {
    messageBus.send('desk-message', {
      title: "New version available",
      body: "A new version of the app is available. Please download it ASAP.",  
      timeoutType: "never"
    });
    linkOpener({target: {href: DOWNLOAD_URL}});
  }
}