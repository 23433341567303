export const TABS ={
    session: 's',
    projects: 'p',
    todos: 'ts',
    todo: 't',
    history: 'h',
    favorites: 'f',
    settings: 'su',
    dashoard: 'd'
  }

export const SPARE_TIME_PROJECT_ID = 973;
export const TEMP_PROJECT_ID = 1013;

export const IDLE_TIME = 15*60;

export const TODO_NOTIFICATON_INTERVAL = 60*30;
export const ESTIMATION_NOTIFICATON_INTERVAL = 10; //percent

export const MAX_FAV_ENTRIES = 30;

export const DESK_URL = 'https://www.creativedesk.de';
export const DOWNLOAD_URL = 'https://new.creativedesk.de';

export const TICK_TIME_INTERVAL= 2*1000; //mili seconds
export const UPDATE_INTERVAL = 15; //seconds

export const GIPHY_API_KEY = 'LLl4iqJgDS2G98ryX6AWrEMyX049tJnQ'

export const REQUIRED_DESK_VERSION = '2.1.0'
