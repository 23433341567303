
import {  
    Box,
    ToggleButtonGroup,
    ToggleButton 
} from "@mui/material";
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import TodayIcon from '@mui/icons-material/Today';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

export function SortChoice({sortBy, setSortBy, withPriority = false}) {
    return <Box sx={{
      display: "flex",
      alignItems: "center",
      pl: 2
    }}>
      <ToggleButtonGroup
        value={sortBy}
        exclusive
        onChange={(e, sort) => sort && setSortBy(sort)}
        aria-label="Sort"
      >
        <ToggleButton value="az" aria-label="alphabetically">
          <SortByAlphaIcon />
        </ToggleButton>
        <ToggleButton value="date" aria-label="newest first">
          <TodayIcon />
        </ToggleButton>
        { withPriority && <ToggleButton value="prio" aria-label="priority">
          <SignalCellularConnectedNoInternet0BarIcon />
        </ToggleButton> }
      </ToggleButtonGroup>
    </Box>;
  }