import { red, grey, green, yellow, orange, blue, amber, indigo, deepOrange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const common = {
    typography: {
        h1: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 500
        },
        h3: {
            fontSize: '1.2rem',
            fontWeight: 500
        },
    },
    components: {
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 60,
                },
            },
        }
    },
}


export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: grey[50],
          },
          secondary: {
            main: grey[400],
          },
          error: {
            main: '#ff0000',
          },
          background: {
            default: '#191B1F',
            paper: '#232427',
          },
          text: {
            primary: grey[50],
            secondary: grey[400],
            disabled: grey[600],
          },
          success: {
            main: '#ffffff',
          },
          warning: {
            main: orange[500],
          },
          info: {
            main: '#ffffff',
          },
      },
      ...common
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: indigo[900],
        },
        secondary: {
        main: deepOrange[400],
        },
        error: {
        main: red[500],
        },
        background: {
        default: grey[100],
        paper: '#ffffff',
        },
        text: {
        primary: '#000000',
        secondary: grey[800],
        disabled: grey[500],
        },
        success: {
        main: green[400],
        },
        warning: {
        main: amber[500],
        },
        info: {
        main: blue[600],
        },
    },
    typography: {
        ...common.typography,
    },
    components: {
        ...common.components,
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 25,
                },
            },
        }
    }, 

});
