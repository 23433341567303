import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TICK_TIME_INTERVAL, UPDATE_INTERVAL} from '../services/constants';

export const start = createAsyncThunk('session/start', async (session, thunkAPI) => {
  return { 
    interval: setInterval(() => {
        thunkAPI.dispatch(tick());
      }, TICK_TIME_INTERVAL),
    session
  }
})

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    time: 0,
    startAt: null,
    interval: null,
    pendingUpdateDate: null,
    timeFromLastUpde: 0,
    idle: false,
    idleStop: true,
    todoId: null,
    todoName: null,
    sessionId: null,
    projectId: null,
    notificationsSent: [],
    note: ''
  },
  reducers: {    
    tick: (state) => {
      const lasttick = state.time;
      state.time = Math.floor((Date.now() - state.startAt)/1000);  
      const timeDiff = state.time-lasttick;
      state.timeFromLastUpde += timeDiff;
      if(!state.pendingUpdateDate && state.timeFromLastUpde >= UPDATE_INTERVAL) {
        state.pendingUpdateDate = Date.now();
      } 
    },
    prestop: (state) => {
      clearInterval(state.interval);
      state.interval = null;
      state.pendingUpdateDate = null;  
      state.timeFromLastUpde = 0;    
      // state.startAt = null;      
    },
    stop: (state) => {
      state.todoId = null;
      state.projectId = null;
      state.sessionId = null;
      state.projectId = null;
      state.time = 0;
      state.note='';
    },
    stopIdle: (state) => {
      state.idleStop = true;
    },
    updateSent: (state) => {
      state.pendingUpdateDate = null;
      state.timeFromLastUpde = 0;
    },
    updateNote: (state, action) => {
      state.note = action.payload;
    },
    markNotificationSent: (state, action) => {
      state.notificationsSent.push(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(start.fulfilled, (state, action) => {
        state.startAt = Date.now();
        state.time = 0;
        state.idleStop = false;
        // state.note = action.payload.note;
        state.interval = action.payload.interval;
        state.todoName = action.payload.session.todoName;
        state.sessionId = action.payload.session.sessionId;
        state.notificationsSent = [];
        if(action.payload.session.idle) {
          state.idle = true;
        } else {
          state.projectId = action.payload.session.projectId;
          state.todoId = action.payload.session.todoId;
          state.idle = false;
        }
      })
  }
})

export const { tick, prestop, stop, stopIdle, updateNote, updateSent, markNotificationSent } = sessionSlice.actions

export default sessionSlice.reducer
