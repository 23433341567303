import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FavouriteIcon from '@mui/icons-material/Favorite';
import { addTask, removeTask } from "../reducers/favReducer";

export default function FavButton({todo, project}) {
  const dispatch = useDispatch();
  const favourites = useSelector(state => state.fav.bookmarks);
  const isFav = favourites.find(fav => fav.id == todo.id);
  const toggleFav = () => {
    if(isFav) {
      dispatch(removeTask({id: todo.id, fav: true}));
    } else {
      dispatch(addTask({todo, project, fav: true}));
    }
  }
  return (
    <Button variant="outlined" fullWidth 
      color={isFav ? "warning" : "secondary"} 
      onClick={toggleFav}
      startIcon={<FavouriteIcon />}>Fav</Button>
    
  )
}