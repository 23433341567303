import { Edit } from "@mui/icons-material";
import { List, 
    ListItem,
    Typography,
    Box,
    IconButton,
    ListItemText,
    Chip,
    Divider
 } from "@mui/material";

import { toDuration, toTime } from "../services/momentHelper";
import { DESK_URL } from "../services/constants";
import linkOpener from "../services/linkOpener";
import { Fragment } from "react";


export default function({sessions, projects}) {
    const today = new Date().getDate();
    const getProjectName = (id) => projects.find(item => item.id == id).name
    
    return (
        <List >
        {sessions?.filter(item=>new Date(item.started_at).getDate()==today).map(item => {
            item.startedAt = new Date(item.started_at);
            item.endedAt = new Date(item.ended_at);
            return (
            <Fragment>
                <ListItem disablePadding key={item.id} >
                    <Box sx={{pr:2}}>
                        <Typography variant="body1">{toTime(item.startedAt)}</Typography>
                        <Typography variant="body2" color="text.secondary">{toTime(item.endedAt)}</Typography>
                    </Box>    
                    <ListItemText secondary={(
                        item.project_id ?
                        <>
                            <Typography component="span" variant="body2" color="text.primary">{getProjectName(item.project_id)}</Typography>
                            { " — " }
                            { item.notes_team_lang ? item.notes_team_lang : <Chip size="small" variant="outlined" color="error" label="MISSING NOTE"/>}
                        </>
                        :
                        "Idle"
                    )}/>
                    { item.project_id && <IconButton onClick={()=>linkOpener(`${DESK_URL}/todo_sessions/${item.id}/edit`)}><Edit/></IconButton> }
                    { item.endedAt && <Chip size="small" label={toDuration(item.startedAt, item.endedAt)} /> }
                </ListItem>
                <Divider/>
            </Fragment>
        )})}
        </List>
    )
}


