import { 
  Stack, 
  LinearProgress, 
  Box,
  TextField,
  Paper, 
  Button,
  Typography, 
  Switch,
  FormControlLabel,
  FormGroup,
  IconButton,
  Divider,
  } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useProjects, useTodo } from "../services/creativedeskApi"
import { useDispatch, useSelector } from "react-redux";
import { setProject, setScreen, setTodo } from "../reducers/deskReducer";
import { TABS, SPARE_TIME_PROJECT_ID, IDLE_TIME } from "../services/constants";
import {toClock, toClockMinutes, toHoursAndMinutes} from '../services/momentHelper'

import StartIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { OpenInBrowser } from "@mui/icons-material";
import PipeToChip from "../components/PipeToChip";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Loader from "../components/Loader";
import { updateNote } from "../reducers/sessionReducer";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import linkOpener from "../services/linkOpener";
import Favourites from "./Favourites";
import Giphy from "../components/Giphy";
import PlayArrow from "@mui/icons-material/PlayArrow";


export default function Session({stopSession, startIdle, sessionMutation, startIdleMutation, startTodo}) {    
  const session = useSelector(state => state.session);  
  const settings = useSelector(state => state.desk.settings);
  const todo = useTodo(session.projectId, session.todoId);
  const dispatch = useDispatch();
  // const desk = useSelector(state => state.desk);
  const todoData = todo.data;
  const projects = useProjects();    
  const project = projects.data?.find(project => project.id == session.projectId);
  const [ markAsDone, setMarkAsDonbe ] = useState(false);

  const selectTodo = () => {
    dispatch(setProject(project));
    dispatch(setTodo(todoData));
    dispatch(setScreen(TABS.todo));
  }

  const startSpareTime = () => {
    dispatch(setProject({id: SPARE_TIME_PROJECT_ID}));
    dispatch(setTodo({id: 108867}));
    startTodo(108867, 'Spare time', 5, SPARE_TIME_PROJECT_ID, 'Spare time');
  }

  const isSpareTime = session.projectId == SPARE_TIME_PROJECT_ID;

  function History() {
    return settings.showHistoryOnSession && <><Divider sx={{mt: 6}}/><Favourites area="history" areaName="History" startTodo={startTodo}/></>
  }

  if(todo.isSuccess) return (
    <Stack gap={2}>       
      <Paper sx={{mx: 2, mt: 2, p: 2 }} elevation={1}>
        <Typography variant="h3" color="text.secondary" sx={{pb: 1}}>{project.name}</Typography>
        <Typography variant="h2" sx={{pb: 3}}>{todoData?.name}<IconButton size="small" onClick={selectTodo}><OpenInNewIcon fontSize="small"/></IconButton></Typography>
          
        <Grid container spacing={2} >
          <Grid xs={6} sm={3}>
            <Button variant="outlined" fullWidth color="secondary" startIcon={<OpenInBrowser />}
              href={`https://www.creativedesk.de/projects/${todoData.projectId}/todos/${todoData.id}`}
              onClick={linkOpener}
              target="_blank">Desk</Button>
          </Grid>
          <Grid xs={6} sm={3}>
            <Button variant="outlined" fullWidth color="secondary" startIcon={<OpenInBrowser />} 
              href={todoData.jiraLink} disabled={!todoData.jiraLink}
              onClick={linkOpener}
              target="_blank">Jira</Button>
          </Grid>    
          <Grid xs={6} sm={3}>
              <TextField label="Estimated" value={todoData?.estimation ? toHoursAndMinutes(todoData?.estimation) : "none"} fullWidth/>
          </Grid>
          <Grid xs={6} sm={3}>
              <TextField label="Worked" value={todoData?.duration ? toHoursAndMinutes(todoData?.duration) : "none"} fullWidth/>
          </Grid>
          { !isSpareTime && <Grid xs={12} sx={{py:3}}>
            <LinearProgress {...todoData.progress} variant="determinate"/>                          
          </Grid> }         
        </Grid>            
      </Paper>           
      { !settings.noGifs && isSpareTime && <Giphy keyword="relax" />}
      <Paper sx={{mx: 2, p: 2 }} elevation={1}>
        <Typography variant="h1" sx={{textAlign: 'right', mb: 2}}>
        {toClock(session?.time)}
        </Typography>
        
        <FormGroup>
          <TextField multiline={true} fullWidth label="Note" defaultValue={session.note} onChange={(e)=>dispatch(updateNote(e.target.value))}/>
          { !isSpareTime && <div><FormControlLabel control={<Switch  />} label="mark as completed" value={markAsDone} onChange={()=>setMarkAsDonbe(!markAsDone)} sx={{mt: 2}}/></div> }       
          <Box sx={{textAlign: "right", mt: 2}}><LoadingButton loading={sessionMutation.isPending} variant="contained" size="large" startIcon={<StopIcon/>} onClick={()=>stopSession(markAsDone)}>Stop work</LoadingButton></Box>
        </FormGroup>
      </Paper>     
    </Stack>
  )

  if(session?.idle && !session?.idleStop) return (
    <Stack gap={2}>
      <Paper sx={{mx: 2, mt: 2, p: 2 }} elevation={1}>
        <Typography variant="h1" sx={{pb: 1}}>Idle</Typography>
        <Typography color="text.secondary">The idle takes up to 15 minutes and will stop automatically after that time.</Typography>
        
        <LinearProgress variant="determinate" color="error" value={(IDLE_TIME-session?.time)/IDLE_TIME*100} size={150} sx={{mt: 4, mb: 1}}/>
        
        <Stack direction="row" spacing={2} sx={{justifyContent: "space-between"}}>
          <Typography variant="h1">
          {toClockMinutes(session?.time)}
          </Typography>
          <Typography variant="h1" color="text.secondary">
          {toClockMinutes(IDLE_TIME-session?.time < 0 ? 0 : IDLE_TIME-session?.time)}
          </Typography>
        </Stack>
                
        <Box sx={{textAlign: "right", mt: 4}}>
          <LoadingButton loading={sessionMutation.isPending} variant="contained" color="error" size="large" startIcon={<StopIcon/>} onClick={()=>stopSession()}>Stop idle</LoadingButton>
        </Box>
      </Paper>
      <History/>
    </Stack>
  )

  if(session.idleStop) return (
    <Stack gap={2}>
      <Paper sx={{mx: 2, mt: 2, p: 2 }} elevation={1}>
        <Typography variant="h1" sx={{pb: 1}}>Stopped</Typography>
        <Typography color="text.secondary" variant="body">Nothing is tracked now.</Typography>
        <Box sx={{textAlign: "right"}}><LoadingButton loading={startIdleMutation.isPending} variant="contained" 
          color="warning" size="large" 
          startIcon={<StartIcon/>} onClick={()=>startIdle()}
          sx={{mt:2}}
        >Start idle</LoadingButton></Box>
      </Paper>
      <Paper sx={{mx: 2, mt: 2, p: 2 }} elevation={1}>
        <Typography variant="h2" sx={{pb: 1}}>Spare time</Typography>
        <Typography color="text.secondary" variant="body">Need a more extended break? Start the dedicated task.</Typography>
        <Box sx={{textAlign: "right"}}><LoadingButton 
          variant="contained" color="primary" size="large" 
          startIcon={<PlayArrow/>} onClick={()=>startSpareTime()}
          sx={{mt:2}}
        >Spare time</LoadingButton></Box>
      </Paper>
      <History startTodo={startTodo}/>
    </Stack>
  )

  return <Loader details={true}/>
}


      