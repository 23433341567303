import { Breadcrumbs, 
  Stack, 
  Link,   
  Typography,
  Alert,
  Button,
  IconButton,
  Box,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  List
 } from "@mui/material";
import { setProject, setScreen, setTodo } from "../reducers/deskReducer";
import { useDispatch, useSelector } from "react-redux";

import { TABS } from "../services/constants";
import { typeToIcon } from "../components/TodosList";
import PipeToChip from "../components/PipeToChip";
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { removeTask } from "../reducers/favReducer";
import { PlayArrow } from "@mui/icons-material";

export default function Favourites({area, areaName, startTodo, withDelete = false}) {
  const todos = useSelector(state => state.fav[area]);
  const dispatch = useDispatch();
  const [preDelete, setPreDelete] = useState(0);
  
  // Handle the selection of a todo
  const selectTodo = (todo) => () => {
    dispatch(setProject({id: todo.projectId}));
    dispatch(setTodo(todo));
    dispatch(setScreen(TABS.todo));
  }

  const remove = (e) => {
    e.stopPropagation();
    if(preDelete == e.currentTarget.id) {
      setPreDelete(0);
      dispatch(removeTask({id: e.currentTarget.id, fav: area == 'bookmarks'}));
      return;
    }
    preDelete == e.currentTarget.id ? setPreDelete(0) : setPreDelete(e.currentTarget.id);
  }

  return (    
    <Box>
      <Typography variant="h1" sx={{p:2}}>
        {areaName}
      </Typography>
      { todos?.length > 0 ? 
        <List >
        {todos?.map(todo => (
          <ListItem key={todo.id} disablePadding 
            secondaryAction={ <>{withDelete &&
              <IconButton edge="end" aria-label="delete" sx={{mr:1}} onClick={remove} id={todo.id}>
                <DeleteIcon color={todo.id == preDelete ? 'error' : 'disabled'}/>
              </IconButton>}
              <IconButton edge="end" sx={{mr:1}} onClick={()=>selectTodo(todo) && startTodo(todo.id, todo.name,todo.todoType, todo.projectId, todo.projectName)}>
                <PlayArrow color="success" />
              </IconButton>
              </>
            }
          >
            <ListItemButton onClick={selectTodo(todo)}>
              <ListItemIcon>{typeToIcon(todo.todoType)}</ListItemIcon>
              <ListItemText primary={todo.name} secondary={todo.projectName} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
        : 
        <Alert severity="info" sx={{m: 2}}>No todos found</Alert> 
      }
    </Box>
  )

}