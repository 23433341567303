import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DESK_URL } from "./constants";

let token = '';
let forbiddenCallback = () => {};

export const setAuthData = (newToken, newForbiddenCallback) => {
  token = newToken;
  forbiddenCallback = newForbiddenCallback;
}

export const login = (username, password) => {
  let params = new URLSearchParams();
  params.append('user[login]', username);
  params.append('user[password]', password);

  return fetch(DESK_URL + '/users/login.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    },
    body: params
  }).then(response => {
    if(response.status === 403) {
      throw new Error('Invalid username or password');
    }
    if(!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
}

const hashUrl = (url) => `${DESK_URL}${url}${url.includes('?') ? '&' : '?' }session_hash=${token}`

const getFromDesk = (url) => {
  return fetch(hashUrl(url)).then(response => {
    if(response.status === 403) {
      forbiddenCallback();
      throw new Error('Forbidden');
    }
    if(!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });
}

const sendToDesk = (method, url, payload={}, json=true) => {
  
  const params = Object.keys(payload).reduce((params, key)=>{
    params.append(key, payload[key]);
    return params;
  }, new URLSearchParams());  
  params.append('session_hash', token);
  return fetch(`${DESK_URL}${url}`, {
  // return fetch(hashUrl(url), {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    },
    body: params
  }).then(response => {
    if(response.status === 403) {
      forbiddenCallback();
      throw new Error('Forbidden');
    }
    if(!response.ok) {
      throw new Error('Network response was not ok');
    }
    if(json) return response.json();
    return response;
  });
}

export const useProjects = () => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => getFromDesk('/projects.json').then((res)=>{
      return res.map(project => {
        return {
          id: project.id,
          budgeted: project.budgeted_hours,
          lastActivityAt: project.last_activity_at,
          worked: project.worked_hours,
          name: project.name,
          description: stripHashtags(project.description).trim(),
          finishDate: project.finish_date,
          todoCount: project.todo_count,
          clientId: project.invoice_company_id,
          approveEstimations: project.approve_estimated_times,
          todoNeedsParent: project.todo_need_parent
        }
      });      
    }),
    retry: Infinity,
    staleTime: 1000 * 15
  });
}

export const useTodos = (projectId) => {
  return useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getFromDesk(`/projects/${projectId}/todos.json`).then(res=>{;
      try {
        return res.map(mapTodoList)
      } catch (e) {
        console.log('Response mapping error', e);
        return [];
      }
    }),
    retry: Infinity,
    staleTime: 1000 * 5
  });    
}

export const useTodo = (projectId, todoId) => {
  return useQuery({
    queryKey: ['todo', todoId],
    queryFn: () => {
      if(!todoId || !projectId) return Promise.reject({});
      return getFromDesk(`/projects/${projectId}/todos/${todoId}.json`).then(res=>{
        return mapTodoList(res);
      })
    },
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 15,
    staleTime: 1000 * 15,    
  });    
}


export const useStartTodoSession = () => {
  return useMutation({
    mutationFn: (todoId) => sendToDesk('POST', `/todos/${todoId}/todo_sessions.json`).then(res=>{      
      return {
        sessionId: res.id,
        todoId: res.todo_id,
        projectId: res.project_id,
        note: res.notes_team_lang,
        start: res.local_started_at
      };
    }),
    onError: (e) => {
      console.log('start todo error', e);
    },
    retry: 5,
    retryDelay: 1000
  })
}

export const useStartIdle = () => {
  return useMutation({
    mutationFn: () => sendToDesk('POST', `/todo_sessions.json`).then(res=>{            
      return {
        sessionId: res.id,
        start: res.local_started_at
      };
    }),
    onError: (e) => {
      console.log('start idle error', e);
    },
    retry: 5,
    retryDelay: 1000
  })
}

export const useUpdateTodoSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({sessionId, note, done, updateTime=true}) => {
      let payload = {
        no_time_update: updateTime ? 0 : 1
      }
      if(note) {
        payload = {
          ...payload,
          notes: note,
          done: done ? 1 : 0,
        }
      }
      
      return sendToDesk('PUT', `/todo_sessions/${sessionId}.json`, payload).then(res=>{      
        return {
          duration: res.duration,
          notes: res.notes_team_lang,
        };
      })
    },
    onSuccess: (e, vars) => {
      if(vars.todoId) {
        queryClient.invalidateQueries({ queryKey: ['todo', vars.todoId] })
      }
    },
    onError: (e) => {
      console.log('update session error', e);
    },
    retry: 3,
    retryDelay: 1000
  })
}

export const useUpdateEstimation = (todoId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (estimation) => {      
      return sendToDesk('POST', `/todos/change_estimated_time/${todoId}.json`, {        
        value: estimation
      }, false).then(res=>{      
        return {};
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['todo', todoId] })
    },
    onError: (e) => {
      console.log('update estimation error', e);
    },
    retry: 4,
    retryDelay: 1000
  })
}

export const useAddTodo = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {    
      return sendToDesk('POST', `/projects/${projectId}/todos.json`, {        
        'todo[name]': data.name,
        'todo[description]': data.description,
        'todo[todo_category_id]': data.type,
        'todo[estimated_time_hours]': data.estimation,
      }).then(res=>{      
        return {
          id: res.id,
          name: res.name_team_language,
          todoType: codeToType(res.todo_category_id),
          projectId: res.project_id
        }
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['project', projectId] })
    },
    onError: (e) => {
      console.log('Todo add error', e);
    },
    retry: 5,
    retryDelay: 1000
  })
}

export const useSessions = (dateFrom = null, dateTo = null) => {
  return useQuery({
    queryKey: ['sessions', dateFrom, dateTo],
    
    queryFn: () => {
      let url = '/todo_sessions.json';
      if(dateFrom && dateTo) {
        url += `?started_at=${dateFrom}&ended_at=${dateTo}`;
      }
      return getFromDesk(url).then(res=>{
        return res;      
      })
    },
    retry: 5,
    retryDelay: 5000,
    refetchInterval: 1000 * 60 * 2,
    // staleTime: 1000 * 1
  });
}

export const useNotifications = (interval=5000) => {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => {
      return getFromDesk('/notifications.json').then(res=>{
        return res;      
      })
    },
    retry: 1,
    retryDelay: 5000,
    refetchInterval: interval
  });
}

export const useBookmarks = () => {
  return useQuery({
    queryKey: ['bookmarks'],
    queryFn: () => {
      return getFromDesk('/bookmarks.json').then(res=>{
        return res;      
      })
    },
    retry: 3,
    retryDelay: 5000    
  });
}

export const useMarkTodoDone = (todoId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {   
      return sendToDesk('PUT', `/todos/${todoId}/done`, {}, false).then(res=>{      
        console.log('done', res);
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['todo', todoId] })
    },
    onError: (e) => {
      console.log('Todo done error', e);
    },
    retry: 5,
    retryDelay: 1000
  })
}

// ====================UTILITIES====================

const stripHashtags = (str) => {
  return str.replace(/#.+(\s|$)/g, '');
}

const mapTodoList = (todo) => {
  return {
    id: todo.id,    
    createdAt: todo.created_at,
    deadline: todo.deadline_at,
    description: todo.desc_team_language,
    // worked: todo.worked_hours,
    name: todo.name_team_language,
    duration: todo.duration_time,
    estimation: todo.estimated_time,    
    // private: todo.is_private,
    parentId: todo.parent_id,
    progress: progress(todo.duration_time, todo.estimated_time, shallAnimate(todo)),
    position: todo.position,
    priority: codeToPriority(todo.priority, todo.is_critical),
    projectId: todo.project_id,
    status: codeToStatus(todo.status),
    subtasksCount: todo.subtasks_count,
    todoType: codeToType(todo.todo_category_id),
    updatedAt: todo.updated_at,
    jiraLink: getJiraLink(todo.desc_team_language),
    done: todo.percentage_completed == 100
  }
}

const shallAnimate = (todo) => {
  if (todo.subtasks_count>0) return false;
  if (todo.todo_category_id == 5 || todo.todo_category_id == 12) return false;
  return true;
}

const codeToType = (code) => {
  switch (code) {
      case 4:
          return 'test';
      case 1:
          return 'bug';
      case 2:
          return 'feature';
      case 3:
          return 'modification';
      case 5:
      case 12:
          return 'support';
      case 6:
          return 'new extra';
      case 7:
          return 'management';
      default:
          return 'other';
  }
};

const codeToPriority = (code, is_critical) => {
  if(is_critical) return 'critical';
  switch (code) {
      case 0:
          return 'very low';
      case 25:
          return 'low';
      case 50:
          return 'medium';
      case 75:
          return 'high';
      case 100:
          return 'very high';
      default:
          return 'unknown';
  }

}

const getJiraLink = (description) => {
  if(!description) return '';
  let match = description.match(/https:\/\/\w+.atlassian.net\/.+?(\s|$)/);
  return match ? match[0] : '';

}

export const progress = (worked, estimated, animate) => {    
  if (estimated == 0) {
    return {
      color: "secondary",
      value: 1,
      percent: 0,
      variant: "determinate"
    }
  }
  let score = 100 * worked/estimated;
  if (worked > (0.9 * estimated)) {
    return {
      color: "error",
      value: 100,   
      percent: Math.floor(score),
      variant: !animate ? "determinate" : "indeterminate"   
    }
  }  
  if (score < 50) {
    return {
      color: "primary",
      value: score,
      percent: Math.floor(score),
      variant: "determinate"
    }
  }
  return {
    color: "warning",
    value: score,
    percent: Math.floor(score),
    variant: "determinate"
  }
}

const codeToStatus = (code) => {
  switch (code) {
      case 0:
          return 'time not approved';
      case 1:
          return 'not started';
      case 2:
          return 'in progress';
      case 3:
          return 'complete';
      default:
          return 'unknown';
  }
}
