import {Chip} from "@mui/material";
import React from 'react';

export default function PipeToChip({ text }) {    
  if(text?.includes('|')) {
    const [name, pill] = text.split('|',2);
    return ( <React.Fragment>
        {name}
        <Chip component="span" label={pill}  variant="outlined" size="small" color="secondary" />
    </React.Fragment>)    
  } return text;
}