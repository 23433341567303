import { createSlice } from '@reduxjs/toolkit'
import { MAX_FAV_ENTRIES } from '../services/constants';

const store = (key, value) => {
  window.localStorage.setItem('list-' + key, JSON.stringify(value));
}

const load = (key) => {
  const loaded = JSON.parse(window.localStorage.getItem('list-' + key, '[]'));
  if(Array.isArray(loaded)) {
    return loaded;
  }
  return [];
}

export const favSlice = createSlice({
  name: 'desk',
  initialState: {
    bookmarks: load('bookmarks'),
    history: load('history')
  },
  reducers: {
    addTask: (state, action) => {
      const area = action.payload.fav ? 'bookmarks' : 'history'
      state[area] = state[area].filter(todo => todo.id != action.payload.todo.id)
      state[area].unshift({
        id: action.payload.todo.id,
        name: action.payload.todo.name,
        todoType: action.payload.todo.todoType,
        projectId: action.payload.project.id,
        projectName: action.payload.project.name        
      })   
      state[area]=state[area].slice(0,MAX_FAV_ENTRIES);
      store(area, state[area]);   
    },
    removeTask: (state, action) => {
      const area = action.payload.fav ? 'bookmarks' : 'history'
      state[area] = state[area].filter(todo => todo.id != action.payload.id)
      store(area, state[area]);
    },
    
  }
})

export const { addTask, removeTask } = favSlice.actions

export default favSlice.reducer
