import { Breadcrumbs, 
  Typography,  
  Box,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  Divider,
  Chip
 } from "@mui/material";
import { setProject, setScreen, setTodo } from "../reducers/deskReducer";
import { useDispatch } from "react-redux";

import { TABS } from "../services/constants";
import { useBookmarks } from "../services/creativedeskApi";

export default function DeskBookmarks() {
  const dispatch = useDispatch();
  const bookmarks = useBookmarks();
  
  // Handle the selection of a todo
  const selectTodo = (todoId, projectId) => () => {
    dispatch(setProject({id: projectId }));
    dispatch(setTodo({id: todoId, projectId}));
    dispatch(setScreen(TABS.todo));
  }

  const selectProject = (projectId) => () => {
    dispatch(setProject({id: projectId }));
    dispatch(setScreen(TABS.todos));
  }

  return bookmarks.isSuccess && bookmarks.data.length > 0 && (    
    <Box>
      <Divider sx={{mt: 2}}/>
      <Typography variant="h1" sx={{p:2}}>
        Bookmarks
      </Typography>
      <List >
      { bookmarks.data?.map(bookmark => {
        if(bookmark.bookmarkable_type == 'Project') return (
          <ListItem key={bookmark.id} disablePadding>
            <ListItemButton onClick={selectProject(bookmark.project_id)}>
              <ListItemText primary={bookmark.params.name}/><Chip label="PROJECT" size="small" color="primary" variant="outlined"/>
            </ListItemButton>
          </ListItem>        
        )
        if (bookmark.bookmarkable_type == 'Todo') return (
          <ListItem key={bookmark.id} disablePadding>
            <ListItemButton onClick={selectTodo(bookmark.params.todo_id, bookmark.project_id)}>
              <ListItemText primary={bookmark.params.todo}/> <Chip label="TODO" size="small" color="secondary" variant="outlined"/>
            </ListItemButton>
          </ListItem>        
        )
      })}
      </List>
    </Box>
  )


}