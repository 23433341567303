import { Box, IconButton, Paper, Typography, Stack, Chip, Tooltip } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from "react-redux";
import { toClock, toHoursAndMinutes, toMinutes, todayDateYYYYMMDD } from "../services/momentHelper";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { setProject, setScreen, setTodo } from "../reducers/deskReducer";
import { ESTIMATION_NOTIFICATON_INTERVAL, TABS, TEMP_PROJECT_ID } from "../services/constants";
import { useAddTodo, useNotifications, useSessions, useTodo } from "../services/creativedeskApi";
import { calcTotalSpareTime, calcTotalTodoTime, calculateTimes } from "../services/statistics";
import messageBus from "../services/messageBus";
import { markNotificationSent } from "../reducers/sessionReducer";
import { useEffect, useState } from "react";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import moment from "moment/moment";

export default function StatusBar({startTodo = null}) {
  const session = useSelector(state => state.session);  
  const settings = useSelector(state => state.desk.settings);
  const dispatch = useDispatch();
  const todo = useTodo(session.projectId, session.todoId);
  const todoData = todo.data;
  const todayDate = todayDateYYYYMMDD();
  const sessions = useSessions(todayDate, todayDate);
  const todoMutation = useAddTodo(TEMP_PROJECT_ID);

  const notifications = useNotifications(settings.muteCreativedeskNotifications ? 3600000 : 5000);

  const [times, setTimes] = useState();
  let spareTime = 0;
  let todoTime = 0;
  let idleTime = 0;
  
  const notifyAboutEstimation = () => {    
    const currentLevelId = Math.floor(todoData?.progress.percent / ESTIMATION_NOTIFICATON_INTERVAL);
    if(isNaN(currentLevelId)) {
      return;
    }
    if(!session.notificationsSent.includes('e!')) {
      dispatch(markNotificationSent('e!'));
      dispatch(markNotificationSent('e-'+currentLevelId));
      return;
    }    
    if(!session.notificationsSent.includes('e-'+currentLevelId)) {
      messageBus.send('desk-message', {
        title: `${todoData?.progress.percent}% of estimated time`,
        body: `${session.todoName} is estimated for ${toHoursAndMinutes(todoData.estimation)}. Work took ${toHoursAndMinutes(todoData.duration)}.`,  
        timeoutType: todoData?.progress.percent > 90 ? 'never' : 'default',
        closeButtonText: 'OK'
      });
      dispatch(markNotificationSent('e-'+currentLevelId));
    }
  }

  const startTemp = () => {
    todoMutation.mutate({
      name: 'Zombie task started at ' + moment().format('HH:mm on dddd'),
      type: 5,
      estimation: 0.5
    }, {
      onSuccess: (data) => {
        dispatch(setTodo(data));
        dispatch(setProject({id: TEMP_PROJECT_ID, name: 'Temp'}));
        startTodo && startTodo(data.id, data.name, data.todoType, TEMP_PROJECT_ID, 'Temp');
      }      
    });
    
  }

  const sendDeskNotifications = () => {
    if(settings.muteCreativedeskNotifications) {
      return;
    }
    let delay = 0;    
    notifications.data.reverse().forEach(notification => {
      setTimeout(() => {
        messageBus.send('desk-message', {
          title: notification.text,  
          timeoutType: 'default',
        });
      }, delay++ * 1500);
      
    });
  }

  if(times) {
    spareTime = calcTotalSpareTime(times?.today);
    todoTime = calcTotalTodoTime(times?.today);
    idleTime = times?.today?.idleTime;
  }

  useEffect(() => {    
    // notifyAboutEstimation();
    todoData && messageBus.send('desk-percent', { value: todoData?.progress.percent, projectId: session.projectId });
  }, [todoData?.progress.percent])

  useEffect(() => { 
    if(notifications.isFetching == false) return;
    notifications.isSuccess && sendDeskNotifications();
  }, [notifications.isFetching, notifications.isSuccess])
  
  useEffect(() => { 
    todo.isSuccess && notifyAboutEstimation();
  }, [todo.isFetched, todo.isSuccess])

  useEffect(() => {
    if(sessions.isSuccess) {
      setTimes(calculateTimes(sessions.data, ()=>({}), todayDate));
    }
  }, [sessions.isFetching,sessions.isSuccess])

  return (
    <Paper elevation={5} sx={{
      p: 2,
      position: "sticky",
      bottom: 0,
      zIndex: 100,
      mt: 2
    }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={5}>
          <Stack direction="row" gap={2} sx={{alignItems: 'center'}}>
            <Typography>
            {toClock(session.time)}
            </Typography>
            { session.sessionId > 0 && session.idle && <Chip  size="small" color="warning" label="IDLE"/> }
            { session.idleStop && <Chip  size="small" color="error" label="STOPED"/> }
            {todo.isSuccess && (
              <>                            
              <Typography variant="body2" 
                color="text.secondary"
                sx={{flexGrow: 2, textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap'}}>
                {todoData?.name}                
              </Typography>              
              <Chip size="small" color={todoData?.progress.color} label={`${todoData?.progress.percent}%`}/>              
              </>
            )}
          </Stack>
          
        </Grid>

        <Grid xs={12} sm={6} smOffset={1}>
          <Stack direction="row" gap={2} sx={{alignItems: 'center'}}>            
            <Typography variant="body2" color="text.primary">
              {`${toHoursAndMinutes(todoTime+idleTime)}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Idle: {`${toMinutes(idleTime+spareTime)}`}
            </Typography>
            <Box sx={{flexGrow: 2}}></Box>
            <IconButton onClick={()=>dispatch(setScreen(TABS.dashoard))}><QueryStatsIcon /></IconButton>
            <IconButton disabled={todoMutation.isPending} color={todoData?.projectId == TEMP_PROJECT_ID ? "error" : "default"} onDoubleClick={startTemp}><MedicalServicesIcon /></IconButton>
          </Stack>
        </Grid>        
      </Grid>

    </Paper>            
  )
}