import * as moment from "moment";


moment().locale("en");
// moment().format('l');   // 09/04/2021

export default moment;

export const ago = (date) => moment(date).fromNow();

export const format = (date) => moment(date).format("HH:mm DD/MM/YYYY");

export const calendar = (date) => moment(date).calendar();

export const toHoursAndMinutes = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');    
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    if(minutes > 0) {
        return `${hours} h, ${minutes} min`;
    } else {
        return `${hours} h`;
    }
}

export const toMinutes = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');    
    const minutes = Math.floor(duration.asMinutes());
    return `${minutes} min`;
}

const pad0 = (num) => {
    return String(num).padStart(2,'0');
}

export const toClock = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');    
    return `${Math.floor(duration.asHours())}:${pad0(duration.minutes())}:${pad0(duration.seconds())}`;
}

export const toHours = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');    
    return `${duration.asHours().toFixed(1)}h`;
}

export const toClockMinutes = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');    
    return `${pad0(Math.floor(duration.asMinutes()))}:${pad0(duration.seconds())}`;
}

export const todayDateYYYYMMDD = () => {
    return new Date().toISOString().split('T')[0];
}


export const toTime = (date) => {
    if(!date) return '';
    return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
}

export const toDuration = (start, end=new Date()) => {
    const diff = moment.duration(end-start);
    return toClock(diff.asSeconds());
    // return `${Math.floor(diff.asMinutes())}:${String(diff.seconds()).padStart(2, '0')}`;
}