import { Stack, TextField } from "@mui/material";
import { SortChoice } from "./SortChoice";
import { useEffect, useRef } from "react";
import { parseProtocol } from "../screens/Desk";

export default function SearchBar({setSearch, sortBy, setSortBy, withPriority = false}) {

    const searchRef = useRef();

    const handleSearch = (e) => {
      const search = e.target.value.toLowerCase();
      
      // Parse Desk urls like
      // https://www.creativedesk.de/projects/766/todos/119535      
      const regex = /\/projects\/(\d+)\/todos\/(\d+)/;
      const match = search.match(regex);
      if (match) {
        const projectId = parseInt(match[1]);
        const todoId = parseInt(match[2]);                
        window.dispatchEvent(new CustomEvent('internal-opentodo', { detail: {projectId, todoId}}));
        return;
      }
      if(search.startsWith('desk:')) {
        try {
          window.dispatchEvent(new CustomEvent('internal-opentodo', { detail: parseProtocol(search)}));          
          return;
        } catch(e) {}        
      }      
      setSearch(search);    
    }

    useEffect(() => {
        const handleCmdF = (event) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
            event.preventDefault();
            searchRef.current.focus();
          }
        };
    
        window.addEventListener('keydown', handleCmdF);
        searchRef.current.focus();
    
        return () => {
          window.removeEventListener('keydown', handleCmdF);
        };
      }, []); 
    

    return (
        <Stack direction="row">
            <TextField fullWidth label="Search" variant="outlined" 
                onChange={handleSearch}
                InputProps={{inputRef: searchRef}}
            />
            <SortChoice sortBy={sortBy} setSortBy={setSortBy} withPriority={withPriority}/>
        </Stack>
    )
}