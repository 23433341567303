
import { LoadingButton } from "@mui/lab";
import { TextField, Typography, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Controller, useForm } from "react-hook-form";
import { useAddTodo } from "../services/creativedeskApi";

export default function AddTodo({projectId, onSuccess}) {  
  const { register, handleSubmit, control, formState:{errors} } = useForm();

  const todoMutation = useAddTodo(projectId);

  const onSubmit = (data) => {
    console.log(data);
    todoMutation.mutate(data, {
      onSuccess: (data) => {
        onSuccess(data);
      }      
    });
  }

  return (  
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12}>              
            <Typography variant="h5">Add Todo</Typography>
          </Grid>
          <Grid xs={12}>
            <TextField 
              label="Name" 
              variant="outlined" 
              fullWidth
              {...register("name", {required: true})}
            />
          </Grid>
          <Grid xs={12}>
            <TextField 
              label="Description" 
              variant="outlined" 
              fullWidth
              multiline
              {...register("description", {required: false})}
            />
          </Grid>
          <Grid xs={6}>
          <FormControl fullWidth>
            <InputLabel id="todo-type">Type</InputLabel>
            <Controller
              name="type"
              control={control}    
              rules={{ required: true }}                          
              defaultValue=""
              render={({ field, fieldState }) => (
                <Select labelId="todo-type" {...field} error={fieldState.invalid}>
                  <MenuItem value={2}>Feature</MenuItem>
                  <MenuItem value={1}>Bug</MenuItem>
                  <MenuItem value={5}>Support</MenuItem>
                  <MenuItem value={6}>Extra</MenuItem>
                </Select>
              
              )}
            />            
          </FormControl>
          </Grid>
          <Grid xs={6}>              
            <FormControl fullWidth>
              <Controller
                name="estimation"
                control={control}
                rules={{ required: true, min: 0.25}}
                defaultValue=""
                render={({ field, fieldState }) => <TextField label="Estimation" type="number" variant="outlined" error={fieldState.invalid}
                  
                  inputProps={{
                    min: 0.25, step: 0.25
                  }}                     
                  
                  {...field}/>}
              />     
            </FormControl>              
          </Grid>
          
          
          <Grid xs={12}>
            <LoadingButton type="submit" variant="contained" color="success" fullWidth loading={todoMutation.isPending}>Start</LoadingButton>
          </Grid>
        </Grid> 
    </form> 
  );
}