
import { 
  Alert,
    Paper,
    Stack,
    TextField,
    Typography 
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { calcTotalSpareTime, calcTotalTodoTime, calculateTimes, countSessionsWithoutNote } from "../services/statistics";
import { useProjects, useSessions } from "../services/creativedeskApi";
import { toHoursAndMinutes } from "../services/momentHelper";
import { green, orange, red } from "@mui/material/colors";
import { SPARE_TIME_PROJECT_ID } from "../services/constants";
import Grid from '@mui/material/Unstable_Grid2';
import linkOpener from "../services/linkOpener";
import SessionsList from "../components/SessionsList";

export default function Dashboard() {
  let projects = useProjects();
  let sessions = useSessions();
  let times;
  const chart = {
    today: [],
    week: [],
    month: []
  };
  const greens= getColorGenerator(green)();
  const reds= getColorGenerator(red)();

  const prodSort = (a, b) => a.production === b.production ? 0 : a.production ? -1 : 1;
  
  const mapProject = (pID) => {
    const project = projects.data?.find(project => project.id == pID);
    if(!project) return {name: 'unknown', color: 'grey'};
    return {
      name: project.name,
      color: project.clientId ? greens.next().value : project.id==SPARE_TIME_PROJECT_ID ? orange[400] : reds.next().value,
      production: project.clientId ? true : false
    }
  }
  if(projects.isSuccess && sessions.isSuccess) {
    times = calculateTimes(sessions.data, mapProject);

    for (const [chartKey, chartValue] of Object.entries(chart)) {      
      times[chartKey].projects.sort(prodSort).forEach(project => {
          chartValue.push({id: project.id, value: project.duration, label: project.name, color: project.color});
      })
      if(times[chartKey].idleTime > 0) {          
        chartValue.push({id: 'idle', value: times[chartKey].idleTime, label: 'idle', color: orange[700]});
      }      
    }
    
  }
  
  if(projects.isSuccess && sessions.isSuccess) {
    const missingNotes = countSessionsWithoutNote(sessions.data);

    return (
    <Stack>
      { missingNotes > 0 && <Alert severity="warning" sx={{m:2, mb: 0, cursor: 'pointer'}} onClick={() => linkOpener("https://www.creativedesk.de/todo_sessions")} 
        >
        {`${missingNotes} session${missingNotes !== 1 ? 's' : ''} this month are missing note`}
      </Alert> }
    <Paper variant="elevation" elevation={3} sx={{
      p: 2, m: 2 
    }}>      
      <Typography variant="h2">
          Today
      </Typography>
      <Stats times={times.today} chart={chart.today}/>
      <Typography variant="h3" sx={{mt:4}}>
          Today's sessions
      </Typography>
      <SessionsList sessions={sessions.data} projects={projects.data}/>
    </Paper>
    <Paper variant="elevation" elevation={3} sx={{
      p: 2, m: 2 
    }}>      
      <Typography variant="h2">
          This week
      </Typography>
      <Stats times={times.week} chart={chart.week}/>
    </Paper>
    <Paper variant="elevation" elevation={3} sx={{
      p: 2, m: 2 
    }}>      
      <Typography variant="h2">
          This month
      </Typography>
      <Stats times={times.month} chart={chart.month}/>
    </Paper>
    </Stack>
    
  )
}}

const valueFormatter = (value) => {
  return toHoursAndMinutes(value.value);
}

  
const getColorGenerator = (color) => function* () {
  while(true) {
    let index = 9;
    while (index >= 4) {
      yield color[100 * index];
      index--;
    }
  }
}

const Stats = ({times, chart}) => {
  const totalTodoTime =calcTotalTodoTime(times);
  const totalSpareTIme = calcTotalSpareTime(times);
  const workBreakRatio = (times.idleTime + totalSpareTIme) / (times.idleTime + totalTodoTime);
  const allowedBreakRatio = 80/(8*60);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{overflowX: "auto"}}>
        <PieChart 
          series={[
            {
              // arcLabel: (item) => `${item.label} (${item.formattedValue})`,
              arcLabelMinAngle: 45,
              data: chart,
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 5,
              valueFormatter

            }
          ]}
          width={400}
          height={300}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
      />
      </Grid>
      <Grid xs={6}>
        <TextField fullWidth label="Total" value={toHoursAndMinutes(times.idleTime + totalTodoTime)}/>              
      </Grid>
      <Grid xs={6}>
        <TextField fullWidth label="Idle & Spare time" value={toHoursAndMinutes(times.idleTime + totalSpareTIme)} error={workBreakRatio > allowedBreakRatio}/>              
      </Grid>
    </Grid>
  )
}
  
