import { Alert, Button, 
  Checkbox, 
  FormControl, 
  FormControlLabel, 
  Paper,
  TextField,
  Typography } from "@mui/material";

import  { useForm, Controller }  from  "react-hook-form";

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../reducers/userReducer';
import { useEffect, useRef, useState } from "react";
import messageBus, { isWeb } from "../services/messageBus";

export default function Login() {
  const storedUsername = window.localStorage.getItem('userlogin')
  const [ loginData, setLoginData ] = useState({
    username: storedUsername,
    password: '',
    remember: false
  })
  
  const { register, handleSubmit, control, formState:{errors} } = useForm({values: loginData});

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    window.localStorage.setItem('userlogin', data.username);    
    dispatch(login(data));
  }
  const passRef = useRef();  
  useEffect(() => {    
    const passwordRecived = (e, password) => {      
      setLoginData({
        ...loginData,
        password,
        remember: true
      })
    }
    if(storedUsername) {
      
      messageBus.on('desk-password', passwordRecived);    
      messageBus.send('desk-getPassword', storedUsername);
    }
    if (passRef.current) {
      passRef.current.focus();
    }

    return () => {
      messageBus.removeAllListeners('desk-password');
    }
  },[])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>      
    <Paper variant="elevation" elevation={3} sx={{
      width: 300,
      p: 4,
      borderRadius: 4,
      mx: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 4,
      my: 4,
    }}>      
      <Typography variant="h1">
          Sign in
      </Typography>
      {user.error && <Alert severity="error" variant="filled">{user.error}</Alert> }
      <FormControl>
        <TextField 
          label="Username" 
          variant="outlined" 
          error={errors.username ? true : false}
          {...register("username", {required: true})}
        />
      </FormControl>
      <FormControl>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField label="Password" type="password" variant="outlined" 
            inputProps={{
              ref: passRef
            }} 
            error={errors.password ? true : false}
            {...field} />}
        />     
        { !isWeb && 
          <FormControlLabel
            control={
              <Controller
                name="remember"
                control={control}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    onChange={onChange}
                    checked={value>0}
                    value={value}
                  />
                )}
              />
            }
            label="Remeber password"
          />
        }                        
      </FormControl>
      
      
      <Button type="submit" variant="contained">Login</Button>
      
    </Paper>
    <img src="/desk.png" style={{ width: 200, display: "block", position: "fixed", bottom: 50, right: 50}} />    
    
    </form>
  
  )
}