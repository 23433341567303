const fromElectron = window.electron?.messanger;

const local = {
    on: (key, callback) => {
        console.log(`Not listening for ${key}`);
    },
    send: (key, payload) => {
        if (!("Notification" in window)) return;
        // let x = new SpeechSynthesisUtterance(payload.title + ' ' + payload.body);
        // x.lang='en';
        // window.speechSynthesis.speak(x);
        if (key === 'desk-message' && Notification.permission === "granted") {
            let notification = new Notification(payload.title, {
                body: payload.body  ,
                requireInteraction: payload.timeoutType === 'never',
            });
        }
    },
    removeAllListeners: (key) => {
        console.log(`Removed listner for ${key}`);
    }

}

const electron = {
    on: (key, callback) => {
        // console.log(`Listening for ${key}`);
        fromElectron.on(key, callback);
    },
    send: (key, payload) => {
    // console.log(`Sending ${key}`, payload);
       fromElectron.send(key, payload);
    },
    removeAllListeners: (key) => {
       fromElectron.removeAllListeners(key);        
    }
}

export const askPermission = () => {
    if (!("Notification" in window)) {
        alert('This browser does not support desktop notification');
    }
    if (Notification.permission !== "granted") {
        Notification.requestPermission().then(permission => {
            alert(permission);
        });
    } else {
        alert('Permission already granted');
    }
}

export const isWeb = !window.electron ? true : false;

export default !isWeb ? electron : local;