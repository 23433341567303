export default function linkOpener(event) {
  if(typeof event === 'string') {
    event = {target: {href: event}};
  };
  if(event.preventDefault) event.preventDefault();
  if(window.electron && event.target.href) {
    window.electron.openExternal(event.target.href);
    return;
  };  
  if(event.target.href) {
    window.open(event.target.href, '_blank');
  }
}